<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :showLogo="false" @pressedGoHome="tryExit"></spot-header>
		<ion-content :fullscreen="true">
			<div class="movement-code-area" v-if="isActive && currentRec">
				<span class="movement-code">{{ currentRec.code }}</span>
			</div>
			<div id="fieldsContainer" class="content-rows" v-if="isActive">
				<web-socket-barcode @scanned="onScan"></web-socket-barcode>
				<div v-if="currField.id==0" class="review-items"> <!-- currField.name == 'review' -->
					<span v-if="currField.required">*</span>
					<ion-item slot="start" lines="full" v-for="field in reviewFields.list" :key="field.id">
						<ion-label v-if="field.type=='text'">{{ field.text }}</ion-label>
						<ion-input v-if="field.type=='text' && field.value" type="text" readonly v-model="field.value"></ion-input>
						<ion-label v-if="field.type=='image'" position="fixed">{{ field.text }}</ion-label>
						<ion-thumbnail v-if="field.type=='image' && field.value"><img :src="field.value" /></ion-thumbnail>
					</ion-item>
					<ion-item slot="start" lines="full" class="hidden" v-for="field in reviewFields.extra" :key="field.id">
						<ion-label v-if="field.type=='text'">{{ field.text }}</ion-label>
						<ion-input v-if="field.type=='text' && field.value" type="text" readonly v-model="field.value"></ion-input>
						<ion-label v-if="field.type=='image'" position="fixed">{{ field.text }}</ion-label>
						<ion-thumbnail v-if="field.type=='image' && field.value"><img :src="field.value" /></ion-thumbnail>
					</ion-item>
				</div>
				<div v-if="currField.id==1"> <!-- currField.name == 'tracking_number' -->
					<spot-input
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:type="currField.inputType"
						:clearInput="true"
						:canUnlock="currField.canUnlock"
						@textChanged="changedInput"
						@committed="committedInput">
					</spot-input>
				</div>
				<div v-if="currField.id==2"> <!-- currField.name == 'carrier_name' -->
					<spot-input
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:type="currField.inputType"
						:clearInput="true"
						:canUnlock="currField.canUnlock"
						@textChanged="changedInput"
						@committed="committedInput">
					</spot-input>
				</div>
				<div v-if="currField.id==3"> <!-- currField.name == 'sign' -->
					<div class="items-label">
						<ion-label>{{ currField.text }}</ion-label>
						<span v-if="currField.required">*</span>
					</div>
					<div class="items-data">
						<signature v-if="!isClosed" @saved="assignSignature" @removed="clearSignature"></signature>
						<ion-button fill="clear" :disabled="!currField.canAddItem" v-if="isVisibleButton(currField.canAddItem) && !isClosed" @click="addItem(currField.refTable)">
							<ion-icon slot="icon-only" :icon="addCircleOutline" size="large"></ion-icon>
						</ion-button>
					</div>
				</div>
			</div>
			<div id="actionsContainer" v-if="isActive">
				<spot-button
					v-if="isVisibleButton(currField.canGoBack)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronBackCircleOutline"
					:enabled="currField.canGoBack"
					:expand="null"
					:text=null
					@clicked="goBack()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canSkip)"
					fill="clear"
					size="large"
					color="primary"
					icon="playSkipForwardCircleOutline"
					:enabled="!isEmptyRequiredFields()"
					:expand="null"
					:text=null
					@clicked="goToSave()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canSave && !isClosed)"
					:fill="(showActionButtonsText) ? 'outline' : 'clear'"
					size="large"
					color="primary"
					icon="save"
					:enabled="!isEmptyRequiredFields()"
					:expand="null"
					:text="(showActionButtonsText) ? labelSave : null"
					@clicked="updateRecord()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canModify)"
					fill="outline"
					size="large"
					color="primary"
					icon="documentTextOutline"
					:enabled="!isDisableButton(currField.canModify)"
					:expand="null"
					:text="isClosed ? labelView : labelModify"
					@clicked="modifyRecord()">
				</spot-button>
				<spot-button
					v-if="isClosed"
					fill="outline"
					size="large"
					color="primary"
					icon="folderOpenOutline"
					:enabled="true"
					:expand="null"
					:text="labelReOpen"
					@clicked="openClosedMovement()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canPickUp && !isClosed)"
					fill="outline"
					size="large"
					color="primary"
					icon="downloadOutline"
					:enabled="!isDisableButton(currField.canPickUp)"
					:expand="null"
					:text="labelDelivery"
					@clicked="goAhead()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canGoAhead)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronForwardCircleOutline"
					:enabled="!isDisableButton(currField.canGoAhead)"
					:expand="null"
					:text=null
					@clicked="goAhead()">
				</spot-button>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				Current Field: {{ currField }}<br>
				<div v-for="field in fields.list" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>
		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped>
	ion-label {
		font-weight:bold;
	}
	.content-rows {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		/*justify-content: space-around;*/
		/*align-content: flex-start;*/
		/*padding: 1em;*/
		/*height: 10%;*/
		/*overflow: auto;*/
	}
	.hidden {
		--ion-background-color:lightgray;
	}
	.items-label {
		margin-top: .5em;
		margin-left: 1em;
	}
	.items-data {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		justify-content: space-between;
		text-align: left;
		margin-left: .5em;
		margin-right: .5em;
	}
	.movement-code-area {
		text-align: center;
		margin-top: .5em;
	}
	.movement-code {
		background: var(--ion-color-secondary);
		font-weight: bold;
	}
	.review-items ion-item {
		--min-height: 0px;
	}
	.review-items ion-input {
		--padding-top: .5vh;
		--padding-bottom: .5vh;
	}
	.review-items :is(ion-label, ion-thumbnail) {
		margin-top: .5vh;
		margin-bottom: .5vh;
	}
</style>

<script>
import WebSocketBarcode from "@/components/WebSocketBarcode.vue";
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonThumbnail } from "@ionic/vue";
import { addCircleOutline, chevronBackCircleOutline, chevronForwardCircleOutline, documentTextOutline, downloadOutline, folderOpenOutline, playSkipForwardCircleOutline, save } from "ionicons/icons";
import { defineComponent } from "vue";
import Signature from "../components/Signature.vue";
import SpotButton from "../components/SpotButton.vue";
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";
import SpotInput from "../components/SpotInput.vue";
import { checkUserSession, confirmYesNo, settings, showMsgError, showMsgInfo, showMsgWarning } from "../plugins/common.js";
import { backendUrl } from "../plugins/variables.js";

	export default defineComponent({
		name: "FinalizeShipment",
		props: {
			pageTitle: { type: String, default: "Concludi Spedizione" },
			exitConfirmTitle: { type: String, default: "Conferma Uscita" },
			exitConfirmMessage: { type: String, default: "Confermi l'uscita dalla maschera di inserimento, annullando eventuali dati inseriti (i dati andranno persi)?"},
			labelSave: { type: String, default: "Salva" },
			labelView: { type: String, default: "Visualizza" },
			labelModify: { type: String, default: "Modifica" },
			labelDelivery: { type: String, default: "Consegna" },
			labelReOpen: { type: String, default: "Sblocca" },
			messageTitle: { type: String, default: "Chiusura Spedizione" },
			successUpdateMessage: { type: String, default: "Spedizione avvenuto correttamente" },
			warningUpdateNoRowMessage: { type: String, default: "Non ci sono articoli da spedire" },
			warningUpdateMessage: { type: String, default: "ATTENZIONE! Alcuni articoli non sono stati aggiornati" },
			errorUpdateMessage: { type: String, default: "Errore durante il salvataggio della spedizione" },
			errorUpdateStateMessage: { type: String, default: "Errore durante la riapertura della spedizione" },
			reopenConfirmMessage: { type: String, default: "Confermi la riapertura della spedizione già chiusa?"},
			successMailMessage: { type: String, default: "Notificata spedizione via mail a: " },
			successMailMessages: { type: String, default: "Notificata spedizione via mail ai Mittenti " },
			errorMailMessage: { type: String, default: "Errore durante l'invio della mail di notifica" },
			unselected: { type: String, default: "Nessuno" },
		},
		components: {
			IonPage,
			IonItem,
			IonButton,
			IonIcon,
			IonContent,
			IonLabel,
			IonInput,
			IonThumbnail,
			Signature,
			SpotHeader,
			SpotButton,
			SpotInput,
			SpotFooter,
			WebSocketBarcode,
		},
		data: () => ({
			isActive: null,
			isClosed: null,
			sender: null,
			header_id: null,
			header_code: null,
			multipack: null,
			fields: {
				currentId: 0,
				list: [ //TODO: Nota che, in realtà canSkip, è poi uguale a NOT required (forse può essere rimosso), forse solo nel riepilogo è diverso, ma ci si può guardare
					{ id: 0, name: 'review', text: 'RIEPILOGO', allignLabel: false, placeholder: '', refTable: null, inputType: "readOnlyTexts", defaultValue: null, value: null, required: false, refField: null, canAddItem: false, canSkip: false, canGoBack: false, canGoAhead: false, canSave: false, canModify: true, canPickUp: true, canRefresh: false, canUnlock: false },
					{ id: 1, name: 'tracking_number', text: 'Track ID', allignLabel: false, placeholder: 'Inserisci ID Spedizione', refTable: null, inputType: "text", defaultValue: null, value: null, required: false, refField: null, canAddItem: false, canSkip: true, canGoBack: false, canGoAhead: true, canSave: false, canModify: false, canPickUp: false, canRefresh: false, canUnlock: false },
					{ id: 2, name: 'carrier_name', text: 'Cognome Corriere', allignLabel: false, placeholder: 'Inserisci Nome e Cognome Corriere', refTable: null, inputType: "text", defaultValue: null, value: null, required: false, refField: null, canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canModify: false, canPickUp: false, canRefresh: false, canUnlock: false },
					{ id: 3, name: 'sign', text: 'Firma', allignLabel: false, placeholder: 'Firma Documento', refTable: null, inputType: "button", defaultValue: null, value: null, required: false, refField: null, canAddItem: false/*true*/, canSkip: false, canGoBack: true, canGoAhead: false, canSave: true, canModify: false, canPickUp: false, canRefresh: false, canUnlock: false },
				],
			},
			reviewFields: {
				list: [
					{ id: 0, name: 'employee', text: 'Richiedente:', value: null, type: 'text' },
					{ id: 1, name: 'addressee', text: 'Destinatario:', value: null, type: 'text' },
					{ id: 2, name: 'carrier', text: 'Corriere:', value: null, type: 'text' },
					{ id: 3, name: 'item', text: 'Formato:', value: null, type: 'text' },
					{ id: 4, name: 'pictures', text: 'Immagine:', value: null, type: 'image' },
					{ id: 5, name: 'note', text: 'Note:', value: null, type: 'text' },
				],
				extra: [
					{ id: 0, name: 'tracking_number', text: 'Track ID:', value: null, type: 'text' },
					{ id: 1, name: 'carrier_name', text: 'Cognome Corriere:', value: null, type: 'text' },
					{ id: 2, name: 'sign', text: 'Firma:', value: null, type: 'image' },
				],
			},
			currentRec: {},
			tablesData: {
				employees: [],
				project: null,
				projectRows: [],
			},
			showActionButtonsText : false,
			hasChangedSignature : false,
			chevronBackCircleOutline,
			chevronForwardCircleOutline,
			playSkipForwardCircleOutline,
			addCircleOutline,
			save,
			documentTextOutline,
			downloadOutline,
			folderOpenOutline,
			settings,
			confirmYesNo,
			checkUserSession,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
			backendUrl,
		}),
		computed: {
			currField() {
				return this.fields.list[this.fields.currentId]
			},
			allOpenedProjectRows() {
				return {
					params: {
						'q[project_id_eq]': this.header_id,
						'q[closed_eq]': false, //to show only not closed
					}
				}
			},
		},
		async ionViewWillEnter() {
			this.getAllTablesData();
			this.setDefault();
			this.getCurrentRec();
			await this.getCurrentProject();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			//Settings
			tryExit() {
				if (!this.isClosed && this.currField.id!=0) {
					this.confirmYesNo(this.exitConfirmTitle, this.exitConfirmMessage)
					.then((data) => {
						if (data == true) {
							if (!this.multipack) this.$router.push("/home");
							else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
						}
					})
				}
				else {
					if (!this.multipack) this.$router.push("/home");
					else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
				}
			},
			getPlaceHolder() {
				return ((this.settings.showFieldsPlaceholder) ? (this.currField.required ? this.currField.placeholder : this.unselected ) : '')
			},
			isDisableButton(ability) {
				if (this.settings.fieldsCheckOnButtons && ability) {
					return (this.currField.required && !this.currField.value) ?  true : false
				}
				else {
					return !ability
				}
			},
			isEmptyRequiredFields() {
				let ret = false;
				this.fields.list.forEach(element => {if (element.required && !element.value) ret=true});
				return ret;
			},
			isVisibleButton(ability) {
				return (this.settings.hideUnneededButtons) ? ability : true
			},
			getEmployeeDepartment(srcObject) {
				let infoDepartment = "";
				if (srcObject.department)
					infoDepartment = " (" + srcObject.department.name + ")"
				else if (srcObject.seat)
					infoDepartment = " (" + srcObject.seat + ")"
				else if (srcObject.seat_code)
					infoDepartment = " (" + srcObject.seat_code + ")"
				return srcObject.name + " " + srcObject.surname + infoDepartment
			},
			//Database
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch("tables/querySearch", { model: tableName, data: filters })
				return data;
			},
			async readData(tableName, recordId) {
				let data = await this.$store.dispatch("tables/read", { model: tableName, id: recordId })
				return data;
			},
			async updateData(tableName, recordId, record) {
				let data = await this.$store.dispatch("tables/update", { model: tableName, id: recordId, data: record })
				return data;
			},
			async updateFormData(tableName, recordId, record) {
				let data = await this.$store.dispatch("tables/updateForm", { model: tableName, id: recordId, data: record })
				return data;
			},
			async bulkUpdateData(tableName, recordId, record) {
				let data = await this.$store.dispatch("tables/bulkUpdate", { model: tableName, id: recordId, data: record })
				return data;
			},
			async bulkUpdateFormData(tableName, recordId, record) {
				let data = await this.$store.dispatch("tables/bulkUpdateForm", { model: tableName, id: recordId, data: record })
				return data;
			},
			getAllTablesData() {
			},
			getCurrentRec() {
				if (this.$route.params.id) {
					this.readData("project_rows", this.$route.params.id)
					.then(data => {
						this.currentRec = data;
						//this.reviewFields.list[0].value=(this.currentRec.employee) ? this.currentRec.employee.name + ' ' + this.currentRec.employee.surname + (!this.currentRec.employee.department ? "" : " (" + this.currentRec.employee.department.name + ")") : null;
						this.reviewFields.list[0].value=(this.currentRec.employee) ? this.getEmployeeDepartment(this.currentRec.employee) : null;
						this.reviewFields.list[1].value=(this.currentRec.addressee) ? this.currentRec.addressee.name : null;
						this.reviewFields.list[2].value=(this.currentRec.carrier) ? this.currentRec.carrier.name : null;
						this.reviewFields.list[3].value=(this.currentRec.item) ? this.currentRec.item.code : null;
						this.reviewFields.list[4].value=(this.currentRec.picture_urls) ? this.backendUrl + this.currentRec.picture_urls : null;
						this.reviewFields.list[5].value=(this.currentRec.notes && this.currentRec.notes.body) ? this.currentRec.notes.body : null;
						this.isClosed=(this.currentRec.closed) ? true : false;
						this.reviewFields.extra[0].value=(this.currentRec.tracking_number) ? this.currentRec.tracking_number : null;
						this.reviewFields.extra[1].value=(this.currentRec.description) ? this.currentRec.description : null;
						this.reviewFields.extra[2].value=(this.currentRec.signature_url) ? this.backendUrl + this.currentRec.signature_url : null;
						this.header_id=this.currentRec.project.id;
						this.header_code=this.currentRec.project.code;
					})
					.catch(error => {
						this.checkUserSession(error, "warning");
					})
				}
				if (this.$route.query.header_id) this.header_id = parseInt(this.$route.query.header_id);
				if (this.$route.query.multipack) this.multipack = (this.$route.query.multipack === 'true');
			},
			async getCurrentProject() {
				if (this.$route.query.header_id) {
					await this.readData("projects", this.header_id)
					.then(data => {
						this.tablesData.project = data;
						this.isClosed=(this.tablesData.project.closed) ? true : false;
						this.header_code = this.tablesData.project.code;
						this.goAhead();
					})
					.catch(error => {
						this.checkUserSession(error, "warning");
					})
				}
			},
			async getOpenedProjectRows() {
				if (this.$route.query.header_id) {
					await this.searchRecords(this.allOpenedProjectRows);
				}
			},
			async searchRecords(currSearch) {
				this.tablesData.projectRows = [];
				await this.searchData("project_rows", currSearch)
				.then(data => {
					this.tablesData.projectRows = data;
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
				})
			},
			prepareRecord() {
				let myRecord = {
					project_row: {
						tracking_number: (this.fields.list[1].value) ? this.fields.list[1].value : null,
						description: (this.fields.list[2].value) ? this.fields.list[2].value : null,
						signature: (this.fields.list[3].value) ? this.fields.list[3].value.id : null,
						closed: true,
					}
				};
				return myRecord;
			},
			prepareBulkRecord() {
				let myRecord = {
					fields: {
						tracking_number: (this.fields.list[1].value) ? this.fields.list[1].value : null,
						description: (this.fields.list[2].value) ? this.fields.list[2].value : null,
						signature: (this.fields.list[3].value) ? this.fields.list[3].value.id : null,
						closed: true,
					}
				};
				return myRecord;
			},
			prepareFormRecord() {
				let myRecord = new FormData();
				
				if (this.fields.list[1].value) myRecord.append('project_rows[tracking_number]', this.fields.list[1].value); else myRecord.append('project_rows[tracking_number]', '');
				if (this.fields.list[2].value) myRecord.append('project_rows[description]', this.fields.list[2].value); else myRecord.append('project_rows[description]', '');
				myRecord.append('project_rows[closed]', true);
				if (this.hasChangedSignature) myRecord.append('project_rows[signature]', this.fields.list[3].value);
				return myRecord;
			},
			prepareBulkFormRecord() {
				let myRecord = new FormData();
				
				if (this.fields.list[1].value) myRecord.append('project_rows[tracking_number]', this.fields.list[1].value); else myRecord.append('project_rows[tracking_number]', '');
				if (this.fields.list[2].value) myRecord.append('project_rows[description]', this.fields.list[2].value); else myRecord.append('project_rows[description]', '');
				myRecord.append('project_rows[closed]', true);
				if (this.hasChangedSignature) myRecord.append('project_rows[signature]', this.fields.list[3].value);
				return myRecord;
			},
			prepareRecordState() {
				let myRecord = {
					project_row: {
						tracking_number: null,
						description: null,
						closed: false,
					}
				};
				return myRecord;
			},
			updateRecord() {
				if (!this.$route.query.header_id) {
					if (!this.fields.list[3].value) this.updateRecordJson();
					else this.updateRecordFormData();
				}
				else {
					if (!this.fields.list[3].value) this.updateBulkRecordsJson();
					else this.updateAllRecordsFormData();
				}
			},
			updateRecordJson() {
				this.updateData("project_rows", this.$route.params.id, this.prepareRecord())
				.then(data => {
					if (data.employee) {
						this.sender = "<br><br>" + this.successMailMessage + "&nbsp" + data.employee.name + "&nbsp" + data.employee.surname;
					} else {
						this.sender = ""/*null*/;
					}
					this.showMsgInfo(
						this.messageTitle,
						this.successUpdateMessage + this.sender);
					if (!this.multipack) this.$router.push("/home");
					else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitle, this.errorUpdateMessage);
				})
			},
			async updateAllRecordsJson() {
				await this.getOpenedProjectRows();
				if (this.tablesData.projectRows.length>0) {
					let state = true;
					this.tablesData.projectRows.forEach(element => {
						this.updateData("project_rows", element.id, this.prepareRecord())
						.catch(error => {
							state = false;
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitle, this.errorUpdateMessage);
						})
					})
					if (state==true) {
						this.showMsgInfo(
							this.messageTitle,
							this.successUpdateMessage + "<br><br>" + this.successMailMessages);
					}
				}
				else {
					this.showMsgWarning(
							this.messageTitle,
							this.warningUpdateNoRowMessage);
				}
				this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
			},
			async updateBulkRecordsJson() {
				await this.getOpenedProjectRows();
				if (this.tablesData.projectRows.length>0) {
					this.bulkUpdateData("projects", this.header_id, this.prepareBulkRecord())
					.then(data => {
						if (data.updated_records == this.tablesData.projectRows.length) {
							this.showMsgInfo(
								this.messageTitle,
								this.successUpdateMessage + "<br><br>" + this.successMailMessages);
						} else {
							this.showMsgWarning(
								this.messageTitle,
								this.warningUpdateMessage);
						}
					})
					.catch(error => {
						this.checkUserSession(error, "warning");
						this.showMsgError(this.messageTitle, this.errorUpdateMessage);
					})
				}
				else {
					this.showMsgWarning(
							this.messageTitle,
							this.warningUpdateNoRowMessage);
				}
				this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
			},
			updateRecordFormData() {
				this.updateFormData("project_rows", this.$route.params.id, this.prepareFormRecord())
				.then(data => {
					if (data.employee) {
						this.sender = "<br><br>" + this.successMailMessage + "&nbsp" + data.employee.name + "&nbsp" + data.employee.surname;
					} else {
						this.sender = ""/*null*/;
					}
					this.showMsgInfo(
						this.messageTitle,
						this.successUpdateMessage + this.sender);
					if (!this.multipack) this.$router.push("/home");
					else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitle, this.errorUpdateMessage);
				})
			},
			async updateAllRecordsFormData() {
				await this.getOpenedProjectRows();
				if (this.tablesData.projectRows.length>0) {
					let state = true;
					this.tablesData.projectRows.forEach(element => {
						this.updateFormData("project_rows", element.id, this.prepareFormRecord())
						.catch(error => {
							state = false;
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitle, this.errorUpdateMessage);
						})
					})
					if (state==true) {
						this.showMsgInfo(
							this.messageTitle,
							this.successUpdateMessage + "<br><br>" + this.successMailMessages);
					}
				}
				else {
					this.showMsgWarning(
							this.messageTitle,
							this.warningUpdateNoRowMessage);
				}
				this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
			},
			async updateBulkRecordsFormData() {
				await this.getOpenedProjectRows();
				if (this.tablesData.projectRows.length>0) {
					this.bulkUpdateFormData("projects", this.header_id, this.prepareBulkFormRecord())
					.then(data => {
						if (data.updated_records == this.tablesData.projectRows.length) {
							this.showMsgInfo(
								this.messageTitle,
								this.successUpdateMessage + "<br><br>" + this.successMailMessages);
						} else {
							this.showMsgWarning(
								this.messageTitle,
								this.warningUpdateMessage);
						}
					})
					.catch(error => {
						this.checkUserSession(error, "warning");
						this.showMsgError(this.messageTitle, this.errorUpdateMessage);
					})
				}
				else {
					this.showMsgWarning(
							this.messageTitle,
							this.warningUpdateNoRowMessage);
				}
				this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
			},
			openClosedMovement() {
				this.confirmYesNo(this.messageTitle, this.reopenConfirmMessage)
				.then((data) => {
					if (data == true) {
						this.updateData("project_rows", this.$route.params.id, this.prepareRecordState())
						.then(() => {
							this.isClosed = false;
							this.currentRec.closed = false;
							this.currentRec.tracking_number = null;
							this.currentRec.description = null;
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitle, this.errorUpdateStateMessage);
						})
					}
				})
			},
			//Actions
			onScan(barcode) {
				if(this.isActive == true) {
					switch (this.currField.id) {
						case 1:
							this.currField.value = barcode
							this.committedInput()
							break;
						default:
							// this.showMsgWarning("Barcode","non è ammessa la lettura di barcode")
					}
				}
			},
			setDefault() {
				this.fields.currentId = 0;
				this.fields.list.forEach(element => element.value = element.defaultValue);
				this.multipack = false;
				this.header_id = null;
				this.header_code = null;
				this.isClosed = false;
				this.hasChangedSignature = false;
			},
			modifyRecord() {
				this.$router.push(`/insert/shipment/${this.$route.params.id}`+ (this.multipack ? `?multipack=true` : ``));
			},
			detectReturn(keyCode) {
				if (keyCode == 13) setTimeout(() => this.goAhead(), 0);
			},
			changedInput(value) {
				this.currField.value = value;
			},
			committedInput() {
				setTimeout(() => this.goAhead(), 0);
			},
			goBack() {
				if (!this.$route.query.header_id) {
					if (this.fields.currentId >= 0 ) this.fields.currentId-=1;
				}
				else {
					if (this.fields.currentId > 1 ) this.fields.currentId-=1;
				}
			},
			goAhead() {
				if (this.fields.currentId <= (this.fields.list.length-1) ) this.fields.currentId+=1
				else this.executeLastFieldAction()
			},
			goToSave() {
				if (this.fields.currentId >= 0 && this.fields.currentId <= (this.fields.list.length-1) ) this.fields.currentId=this.fields.list.length-1
			},
			assignSignature(file) {
				this.hasChangedSignature = true;
				this.currField.value = file;
			},
			clearSignature() {
				this.currField.value = null;
			},
			executeLastFieldAction() {
				//TODO: Da Implementare (vedi quello della Insert, ma forse non serve perchè potrei abilitare solo il pulsante di salvataggio)
			},
			addItem(tableName) {
				if (tableName) {
				//TODO: Possibilmente implementa la funzionalità generica AddItem
				}
			},
		},
	});
</script>